import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  // 巢狀路由
  {
    path: '/',
    component: () => import('../views/Layout.vue'),
    children: [
      {
        path: '',
        redirect: '/surveys',
      },
      {
        path: '/surveys',
        component: () => import('../views/survey/Surveys.vue'),
      },
      {
        path: '/projects',
        component: () => import('../views/project/Projects.vue'),
      },
      {
        path: '/project/:id',
        component: () => import('../views/project/Project.vue'),
      },
      {
        path: '/newcase/:id',
        component: () => import('../views/case/NewCase.vue'),
      },
      {
        path: '/case/:projectId/:caseId',
        component: () => import('../views/case/Case.vue'),
      },
      {
        path: '/results',
        component: () => import('../views/result/Results.vue'),
      },
      {
        path: '/setting',
        component: () => import('../views/Setting.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: '使用者登入',
    component: () => import('../views/Login.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
