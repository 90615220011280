// Vue 核心
import { createApp } from 'vue';
// firebase
import { initializeApp } from 'firebase/app';
// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';
// Bootstrap
import $ from 'jquery';
import 'popper.js';
import '@/assets/scss/main.scss';
import 'bootstrap';
// vue-overlay-loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// animate.css
import animated from 'animate.css';
// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUsers,
  faChartBar,
  faPencilRuler,
  faClinicMedical,
  faCog,
  faSignOutAlt,
  faPlusSquare,
  faEdit,
  faTrashAlt,
  faArrowLeft,
  faAngleDoubleRight,
  faInfoCircle,
  faUserCog,
  faUserEdit,
  faEye,
  faUserSlash,
  faCheckCircle,
  faTimesCircle,
  faPlusCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// entry point 及 router
import App from './App.vue';
import router from './router';

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyCv1XVP8NKnIQJMAWi6m2WDFf9NZ3jAVY4',
  authDomain: 'isopnm-fd8b6.firebaseapp.com',
  projectId: 'isopnm-fd8b6',
  storageBucket: 'isopnm-fd8b6.appspot.com',
  messagingSenderId: '637141458655',
  appId: '1:637141458655:web:adef3a5c04a683995b136a',
  measurementId: 'G-E77DS16QKT',
};
initializeApp(firebaseConfig);

const vm = createApp(App);
// 載入 Axios
vm.use(VueAxios, axios);
// 載入 jQuery
window.$ = $;
// 載入 vue-overlay-loading
vm.use(Loading, { color: '#196bb0' });
// 載入 sweet alert
const options = {
  confirmButtonColor: '#196bb0',
};
vm.use(VueSweetalert2, options);
// 載入 animate.css
vm.use(animated);
// 載入 fontawesome
library.add(
  faUsers,
  faChartBar,
  faPencilRuler,
  faClinicMedical,
  faCog,
  faSignOutAlt,
  faPlusSquare,
  faEdit,
  faTrashAlt,
  faArrowLeft,
  faAngleDoubleRight,
  faInfoCircle,
  faUserCog,
  faUserEdit,
  faEye,
  faUserSlash,
  faCheckCircle,
  faTimesCircle,
  faPlusCircle,
  faExclamationTriangle,
);
vm.component('font-awesome-icon', FontAwesomeIcon);
// 載入 Router
vm.use(router);

vm.mount('#app');
